import React, { useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"

import cactus1 from "../assets/images/cactus_1.png"
import cactus2 from "../assets/images/cactus_2.png"
import cloud1 from "../assets/images/cloud_1.png"
import cloud2 from "../assets/images/cloud_2.png"
import cloud3 from "../assets/images/cloud_3.png"
import cloud4 from "../assets/images/cloud_4.png"
import sign from "../assets/images/sign.png"

const translateKeyframes = keyframes`
    from {
        transform: translateX(calc(200vw + 300px));
    }
    to {
        transform: translateX(0);
    }
`

const Sun = styled.div`
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background: radial-gradient(
        khaki 12%,
        darkorange 80%,
        transparent 90%
    );
    position: absolute;
    right: 10%;
    top: 10%;
    animation: ${keyframes`from { transform: translateY(5px); } to { transform: translateY(-5px); }`} 1s alternate infinite;
`

const Elements = () => {

    const [width, setWidth] = useState(window.innerWidth)

    const Element = styled.img<{ top?: number, bottom?: number, offset: number, delay: number, speed?: number }>`
        position: absolute;
        left: calc(-300px - ${({ offset}) => offset*100 }%);
        ${({ top, bottom }) => top ?
            `top: ${20 + (top! / 1024) * window.innerHeight}px`
        :
            `bottom: ${80 + (bottom! / 1024) * window.innerHeight}px`
        };
        animation: ${translateKeyframes} ${({ speed = 1 }) => (width * 2 + 300) / 222 / speed}s ${({ delay }) => delay}s infinite linear;
    `

    const onResize = () => setWidth(window.innerWidth)

    useEffect(() => {
        window.addEventListener("resize", onResize)
        return () => window.removeEventListener("resize", onResize)
    }, [width])

    return <>
        <Sun />

        <Element src={cactus1} bottom={20} width={150} offset={0.9} delay={1} />
        <Element src={cactus1} bottom={50} width={150} offset={0.2} delay={9} />
        <Element src={sign} bottom={0} width={150} offset={0.5} delay={6} />
        <Element src={cactus2} bottom={40} width={150} offset={0.3} delay={7} />
        <Element src={cactus2} bottom={60} width={150} offset={0.8} delay={2} />

        <Element src={cloud1} top={20} width={300} offset={0.8} delay={2} speed={.5/2} />
        <Element src={cloud2} top={40} width={300} offset={0.4} delay={1} speed={.5/2.4} />
        <Element src={cloud3} top={60} width={300} offset={0.2} delay={4} speed={.5/1.2} />
        <Element src={cloud4} top={90} width={300} offset={0.1} delay={6} speed={.5/1.6} />
    </>
}

export default React.memo(Elements)