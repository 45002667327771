import React, { useEffect, useRef } from "react"

import tooloud from "tooloud"
import styled from "styled-components"

/* Settings */

const locals = {
    layers: [
        { color: `#fcc77b`, speed: 222, sparse: 80 },
        { color: `#da7a30`, speed: 128, sparse: 120 },
        { color: `#c74e39`, speed: 80, sparse: 90 },
    ],
    seed: 4, // 9.1864567
    resolution: 4,
    noiseScale: 0.5,
    offsetY: 0
}

/* Helpers */

const fractalCallback = (x: number, y: number, z: number) =>
    (1+ tooloud.Simplex.noise(x/locals.noiseScale, y/locals.noiseScale, z/locals.noiseScale)) /2
const getNoise = (x: number, y: number, z: number) => {
    z = z || 0;
    return tooloud.Fractal.noise(x, y, z, 5, fractalCallback)/0.22 - 1.74;//factors were hand picked
}

const MountainsCanvas = styled.canvas`
    position: absolute;
    bottom: 80px;
    width: 100%;
    height: 50vh;
`

const Mountains = () => {
    const canvasRef = useCanvas((ctx, time) => {
        const w = ctx.canvas.width
        const h = ctx.canvas.height
        ctx.clearRect(0, 0, w, h)

        const res = locals.resolution
    
        const seed = locals.seed;  
        
        const nLayers = locals.layers.length
        const offsetY = 10
        const factor = 2/1000/window.innerWidth/2
        const sparseFactor = .5 + ctx.canvas.height/1024*1
        for (var i = nLayers - 1; i >= 0; i--) {
            const layer = locals.layers[i]
            //draw mountain
            ctx.fillStyle = layer.color
            ctx.beginPath();    
            // move to bottom left
            ctx.moveTo(0,h);
            // random walk over n points
            var nPoints = Math.ceil(2+(w/res))
            var y0 = h*0.5+(i)/(nLayers-1)*h*0.5;
            for (var j = 0; j <= nPoints; j ++){
                var x = w * j / nPoints;
                var y = y0 - getNoise(j/nPoints+time*factor*layer.speed,1*(i/nLayers+offsetY),seed)*layer.sparse*sparseFactor;
                ctx.lineTo(x, h-y)
            }
            //move to bottom right
            ctx.lineTo(w, h)
            ctx.closePath()
            ctx.fill()
        }

    })

    const onResize = () => {
        canvasRef.current!.width = window.innerWidth
        canvasRef.current!.height = window.innerHeight/2
    }

    useEffect(() => {
        window.addEventListener("resize", onResize)
        return () => window.removeEventListener("resize", onResize)
    }, []) //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <MountainsCanvas ref={canvasRef} width={window.innerWidth} height={window.innerHeight/2} />
    )
}

const useCanvas = (draw: (ctx: CanvasRenderingContext2D, time: number) => void) => {
    const canvasRef = useRef<HTMLCanvasElement>(null)
    useEffect(() => {
        const ctx = canvasRef.current!.getContext("2d")
        const renderFrame = (time: number) => {
            animationFrameId = requestAnimationFrame(renderFrame)
            draw(ctx!, time)
        }
        let animationFrameId = requestAnimationFrame(renderFrame)

        return () => cancelAnimationFrame(animationFrameId)
    })
    return canvasRef
}

export default React.memo(Mountains)