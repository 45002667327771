import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'

import Mountains from "./components/Mountains"
import Elements from "./components/Elements"

import vanImage from "./assets/images/van.png"
import roadImage from "./assets/images/road.jpg"

const FullscreenContainer = styled.div`
    width: 100vw;
    height: 100vh;
    
    background: rgb(168,196,192);
    background: linear-gradient(0deg, rgba(168,196,192,1) 0%, rgba(224,234,228,1) 82%, rgba(224,234,228,1) 100%);
`

const bobbing = keyframes`
    from {
        transform: translateY(-4px) translateX(-50%);
    }
    to {
        transform: translateY(8px) translateX(-50%);
    }
`

const Car = styled.img`
    width: 200px;
    position: absolute;
    bottom: 64px;
    animation: ${bobbing} 0.4s infinite alternate;
    transition: left 1s;
`

const Road = styled.div`
    width: 100%;
    height: 80px;
    background-image: url(${roadImage});
    background-size: contain;
    background-repeat: repeat-x;
    animation: ${keyframes`
        from {
            background-position: 222px;
        }
        to {
            background-position: 0;
        }
    `} 1s infinite linear;
    position: absolute;
    bottom: 0;
`

const Verse = styled.span<{ active: boolean }>`
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    top: 20%;

    color: black;
    font-weight: bold;

    transform: ${({ active }) => active ? "translateY(0)" : "translateY(-64px)"};
    opacity: ${({ active }) => active ? 1 : 0};
    transition: all 1s ${({ active }) => active ? 0.75 : 0}s;
`

const clamp = (min: number, x: number, max: number) => Math.min(Math.max(x, min), max)

const N_PROGRESS = 5
const VERSES = [
    "Comme orientés par les cieux",
    "De Néptune à Mercure,",
    "Il n'existe de plus vrai et précieux",
    "Que l'amour de l'aventure.",
    "~ Ton fils qui t'aime."
]

let lastTouch: Touch | null = null

const App = () => {

    const [progress, setProgress] = useState(0) /* 0 to N_PROGRESS-1 */
    const [animating, setAnimating] = useState(false)

    const onScroll = (e: WheelEvent) => {
        const d = (Math.abs(e.deltaX) > Math.abs(e.deltaY)) ? e.deltaX : e.deltaY
        change((d > 0) ? "down" : "up")
    }
    const onTouchStart = (e: TouchEvent) => lastTouch = e.touches[0]
    const onTouchMove = (e: TouchEvent) => {
        const dx = e.changedTouches[0].clientX - lastTouch!.clientX
        const dy = e.changedTouches[0].clientY - lastTouch!.clientY
        const d = (Math.abs(dx) > Math.abs(dy)) ? dx : -dy
        change((d > 0) ? "down" : "up")
    }

    const change = (dir: "up" | "down") => {
        if (progress === N_PROGRESS-1) return // last slide, can't go back
        if (animating) return
        const targetNext = progress + (dir === "up" ? -1 : 1)
        const next = clamp(0, targetNext, N_PROGRESS-1)
        if (targetNext === next) {
            setAnimating(true)
            setProgress(next)
            setTimeout(() => setAnimating(false), 2000)
        }
    }

    useEffect(() => {
        window.addEventListener("wheel", onScroll)
        window.addEventListener("touchstart", onTouchStart)
        window.addEventListener("touchmove", onTouchMove)
        return () => {
            window.removeEventListener("wheel", onScroll)
            window.removeEventListener("touchstart", onTouchStart)
            window.removeEventListener("touchmove", onTouchMove)
        }
    }, [progress, animating]) //eslint-disable-line react-hooks/exhaustive-deps

    const margin = window.innerWidth * 0.2
    const carFrom = margin
    const carTo = window.innerWidth - margin * 2

    return (
        <FullscreenContainer>
            <Mountains />
            <Elements />
            <Road />
            <Car src={vanImage} style={{ left: (progress === N_PROGRESS-1) ? "calc(100vw + 200px)" : (carFrom + (carTo - carFrom) / (N_PROGRESS-2) * progress) }} />
            {VERSES.map((verse, i) => <Verse key={i} active={progress === i}>{ verse }</Verse>)}
        </FullscreenContainer>
    )
}

export default App
